#receipt-drawer, #upload-drawer {
  > .MuiDrawer-paperAnchorBottom {
    overflow: hidden;
  }

  &.stretched {
    > .MuiDrawer-paperAnchorBottom {
      height: 100%;
    } 
  }

  #upload-preview-container {
    text-align: center;

    img {
      width: 75%;
      box-shadow: 0 0 15px #666;
    }
  }
}
  
#receipt-mask {
  .MuiSvgIcon-root {
    font-size: 3rem;
    // color: white;
    /* background-color: black; */
  }

  [class*=submitFabError] {
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

#admin {
  // .MuiIconButton-colorInherit {
  //   color: rgba(0, 0, 0, 0.54);
  // }

  .react-daterange-picker__calendar {
    z-index: 99;
  }

  .react-daterange-picker__wrapper {
    border: none;

    .react-daterange-picker__inputGroup, .react-daterange-picker__range-divider {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  > .MuiPaper-root {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: none;

    > [class^=Component], > [class^=jss]:not(.MuiToolbar-root) {
      flex: auto;
    }

    @media (min-width: 600px) {
      .MuiToolbar-regular {
        min-height: 48px;
      }
    }
  }

  #options {
    position: absolute;
    bottom: 4px;
    left: 4px;
  }
}

#login {
  form {
    &.loading {
      opacity: 0.7;
    }
  }

}

.loading-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
